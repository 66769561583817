import React from "react"
import ReactPlayer from "react-player"

// react-player component: https://www.npmjs.com/package/react-player
// Responsive video instructions: https://css-tricks.com/aspect-ratio-boxes/

const VideoPlayer = ({
  className = "",
  classNameInner = "",
  classNamePlayer = "",
  url = "https://youtu.be/JTIzhboScV8",
}) => {
  return (
    <div className={`w-full max-w-5xl ${className}`}>
      <div
        className={`relative ${classNameInner}`}
        style={{ paddingTop: "56.25%" }}
      >
        <ReactPlayer
          url={url}
          width="100%"
          height="100%"
          className={`absolute top-0 left-0 ${classNamePlayer}`}
          controls={true}
        />
      </div>
    </div>
  )
}

export default VideoPlayer
